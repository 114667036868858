<template>
  <div class='order-detail'>
    <div v-if="!cancelShow" class="detailContent">
      <div class="topExplain">
         <div class="list-column">
          <img class="img" src="@/assets/geren.png" width="50px" height="50px">
          <div>
            <div class="list-column-name">Halo Studio</div>
            <div v-if="detailContent.status == 'success'">
              <span>下一次收费 {{detailContent.time}}</span> &nbsp;
              <span>金额:￥{{detailContent.money}}</span>
            </div>
            <div v-if="detailContent.status == 'fail'" style="color: #f1909c;">订阅失败</div>
            <div style="color: #f1909c;">
            </div>
          </div>
        </div>
        <div class="border"></div>
        <div class="topExplain-pay">支付方式：<span style="fontWeight: 700;">微信支付</span></div>
      </div>
      <el-collapse accordion>
          <el-collapse-item>
            <template slot="title">
              <i class="header-icon el-icon-date icon"></i>账单历史记录
            </template>
            <el-table
              :data="tableData"
              class="collapse-table"
              style="width: 100%">
              <el-table-column
                prop="date"
                align="center"    
                label="日期">
              </el-table-column>
              <el-table-column
                prop="period"
                align="center"
                label="支付周期">
              </el-table-column>
              <el-table-column
                prop="payFn"
                align="center"
                label="支付方式">
              </el-table-column>              
              <el-table-column
                prop="payMoney"
                align="center"
                label="支付金额">
              </el-table-column>
            </el-table>
          </el-collapse-item>
      </el-collapse>
      <div class="download"  v-if="detailContent.status == 'success'">
        <i class="el-icon-download icon"></i>
        <span>下载 Halo-Studio</span>
      </div>
      <div class="cancel" @click="cancelOrder"  v-if="detailContent.status == 'success'">
        <i class="el-icon-close icon"></i>
        <span>取消订阅</span>
      </div>
    </div>
    <div v-else class="cancelPage">
      <div class="title">一旦期消订阅，你将失去这些权益</div>
      <div style="marginBottom: 100px;">
        · halo studio 编辑器使用权利
      </div>
      <el-button @click="comfirmBtn" class="comfirmStyle">取消订阅</el-button>
      <el-button @click="cancelBtn" class="cancelStyle">保留订阅</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      tableData: [{
        date: '2016-05-02',
        period: '年',
        payFn: '微信支付',
        payMoney: '￥599'
      }, 
      {
        date: '2016-05-04',
        period: '年',
        payFn: '微信支付',
        payMoney: '￥599'
      }],
      cancelShow: false
    }
  },
  props: {
    detailContent: {
      require: true,
      type: Object
    }
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    cancelOrder() {
      this.cancelShow = true
    },
    cancelBtn() {
      this.cancelShow = false
    },
    comfirmBtn() {
    }
  },
}
</script>
<style lang='scss' scoped>
.order-detail {
  .detailContent{
    font-size: 14px;
    border: 1px solid #94ebe6;
    .topExplain {
      padding: 10px 15px;
      .list-column {
        cursor:inherit;
        height: 80px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        .img {
          margin-right: 10px;
        }
        .list-column-name {
          margin-bottom: 10px;
        }
      } 
      .border {
        margin: 0 20px;
        border-bottom: 1px solid #94ebe6;
      }
      .topExplain-pay {
        margin: 10px 0 10px 60px;
      }
    }
    .icon {
      font-size: 30px;
      margin: 0 30px 0 15px;
      vertical-align: middle;
      color: #000;
    }
    .collapse-table {
      margin: 0;
    }
    .download,.cancel{
      font-size: 16px;
      height: 70px;
      line-height: 70px;
      cursor: pointer;
    }
    .download {
      border-bottom: 1px solid #94ebe6;
    }
    .download:hover,.cancel:hover {
      box-shadow: 0px 0px 10px #94ebe655;
    }
  }
  .cancelPage {
    .title {
      width: fit-content;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #333;
    }
    .comfirmStyle {
      background-color: #fff;
      color: #000;
      margin-right: 20px;
    }
    .cancelStyle {
      background-color: #ffc322;
      color: #fff;
      border: 1px solid #ffc322;
    }
  }
}
</style>
<style lang="scss">
.order-detail{
  .detailContent {
    .el-collapse {
      border-top: 1px solid #94ebe6;
    }
    .el-collapse:hover {
      box-shadow: 0px 0px 10px #94ebe655;
    }
    .el-collapse-item__header {
      font-size: 16px;
      height: 70px;
      border-bottom: 1px solid #94ebe6!important;
      .el-collapse-item__arrow {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .el-table {
      font-size: 14px !important;
      border-bottom: 0!important;
      .el-table__cell {
        border-bottom: 1px solid #94ebe6!important;
      }
    }
    .el-table--border::after, .el-table--group::after, .el-table::before {
      content: '';
      position: absolute;
      background-color: #94ebe6;
      z-index: 1;
     }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .el-collapse-item__wrap {
      border-bottom: 0;
    }
  }
}

</style>