<template>
<div  class="order-form">
  <div v-if="orderList.length <=0" class="orderNone">
    <img src="@/assets/no.png" />
  </div>
  <orderList :orderList="orderList" v-else/>

</div>
</template>
<script>
import orderList from './components/order-list'
export default {
  data() {
    return {
      orderList: [
        {
          name: 'Halo Studio',
          time: '2023/4/10',
          money: '599.00',
          status: 'success'
        },
        {
          name: 'CatchLightPlugin',
          time: '2021/4/10',
          money: '399.00',
          status: 'success'

        },
        {
          name: 'Halo Studio',
          time: '2023/4/10',
          money: '399.00',
          status: 'fail'
        }
      ]
    }
  },
  components:{
    orderList
    // orderdetail,
    // ordercancel
  },
  methods: {
  }
}
</script>
<style lang='scss' scoped>
  .order-form {
    height: 700px;
    .orderNone {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items:center;
    }
  }
</style>
