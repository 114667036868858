<template>
  <div class='orderlist'>
    <template v-if="!detailShow">
      <div v-for="(item,index) in orderList" :key="index" class="list">
        <div class="list-column" @click="detailShowFn(item)">
          <img class="img" src="@/assets/geren.png" width="50px" height="50px">
          <div>
            <div class="list-column-name">{{item.name}}</div>
            <div v-if="item.status == 'success'">
              <span>下一次收费 {{item.time}}</span> &nbsp;<span>金额:￥{{item.money}}</span>
            </div>
            <div v-if="item.status == 'fail'" style="color: #f1909c;">订阅失败</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <orderdetail :detailContent="detailContent" />
    </template>
  </div>
</template>
<script>
import orderdetail from './order-detail'
export default {
  name: "",
  data() {
    return {
      detailShow: false,
      detailContent: {},
    }
  },
  props:{
    orderList: {
      require: true,
      type: Array
    }
  },
  components: {
    orderdetail
  },
  created() {},
  mounted() {},
  methods: {
    detailShowFn(item) {
      this.detailContent = item;
      this.detailShow = true;
    }
  },
}
</script>
<style lang='scss' scoped>
  .orderlist {
    .list {
      .list-column {
        margin-bottom: 15px;
        cursor:pointer;
        padding-left: 15px;
        height: 80px;
        border: 1px solid #94ebe6;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        // border-radius: 40px;
        .img {
          margin-right: 10px;
        }
        .list-column-name {
          margin-bottom: 10px;
        }
      }
      .list-column:hover {
        box-shadow: 0px 0px 10px #94ebe655;
      }
    }
  }
</style>